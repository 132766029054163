<template>
  <div class="grid grid-pad">
    <div class="col-1-1">
      <h1>{{ $t("Privacy Statement") }}</h1>
      <div v-html="text"></div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'

export default {
  name: 'Privacy',
  data () {
    return {
      text: ''
    }
  },
  beforeCreate () {
  },
  mounted () {
    this.getPage()
  },
  computed: {
    languageId () {
      return this.$store.state.language.id
    }
  },
  methods: {
    getPage () {
      ApiService.get('/Legal/Privacy', {
        languageId: this.$store.state.language.id
      })
        .then(response => {
          this.text = response.data.data.text
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  watch: {
    languageId () {
      this.getPage()
    }
  }
}
</script>
